/** @jsx jsx */

import { Link } from 'gatsby'
import { jsx } from 'theme-ui'

import { formatFace, getBackgroundColor, getColor } from '../utilities'

const Chip = ({ code, number, owner, status, image_preview_url, name, token_id }) => {
  return (
    <li id={`character-${number}`}>
      <Link
        to={`/characters/${number}`}
        sx={{
          textDecoration: `none`,
          color: `inherit`,
          position: `relative`,
          aspectRatio: `1`,
          display: `grid`,
          alignItems: `center`,
          justifyContent: `center`,
          bg: getBackgroundColor({ code, owner, status }),
          display: `grid`,
          placeContent: `center`,

          ':hover, :focus': {
            outline: `1px solid ${getColor({ code, owner, status })}`,
            outlineOffset: `-3px`,
          },
        }}
      >
        {image_preview_url && (
          <img
            src={image_preview_url}
            alt={name}
            sx={{
              size: 130,
              display: `block`,
              my: 0,
              aspectRatio: `1`,
            }}
          />
        )}

        {!image_preview_url && (
          <pre
            sx={{
              textAlign: `center`,
              aspectRatio: `1`,
              display: `block`,
              my: 0,
              fontSize: `20px`, // `1.25em`,
              fontFamily: `monospace`,
              fontWeight: `500`,
              lineHeight: `1.2`,
              position: `relative`,
              color: getColor({ code, owner, status }),
            }}
          >
            {formatFace(code)}
          </pre>
        )}

        <div
          sx={{
            p: 1,
            textAlign: `right`,
            textOverflow: `ellipsis`,
            overflowX: `hidden`,
            whiteSpace: `nowrap`,
            position: `absolute`,
            bottom: 0,
            right: 0,
            fontSize: `0.625rem`,
            fontFamily: `sans-serif`,
            color: getColor({ code, owner, status }),
          }}
        >
          #{number || token_id}
        </div>
      </Link>
    </li>
  )
}

export default Chip
