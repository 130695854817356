/** @jsx jsx */

import { jsx } from 'theme-ui'

import { features } from '../constants'
import { faces } from '../utilities'
import Chip from './chip'
import Footer from './footer'
import Halo from './halo'
import Header from './header'

const Collection = ({ location }) => {
  const attribute = (
    location.pathname.substr(location.pathname.indexOf(`/collections/`) + `/collections/`.length) || ``
  )
    .split(`-`)
    .join(` `)

  let options = [
    features.headOptions,
    features.eyesOptions,
    features.noseOptions,
    features.mouthOptions,
    features.chinOptions,
  ]
  let category

  if (features.headOptions.includes(attribute)) category = 0
  if (features.eyesOptions.includes(attribute)) category = 1
  if (features.noseOptions.includes(attribute)) category = 2
  if (features.mouthOptions.includes(attribute)) category = 3
  if (features.chinOptions.includes(attribute)) category = 4

  const collectionId = (options[category] || []).indexOf(attribute)
  const characters = faces.filter((face) => face.code[category] === `${collectionId}`)

  return (
    <div sx={{ display: `grid`, fontFamily: `body`, gap: 3 }}>
      <Halo
        description={`All characters that share the "${attribute}" attribute`}
        title={`The ${attribute} collection`}
      />

      <Header />

      <section sx={{ py: 5, px: [`0.5rem`, `0.75rem`], bg: `sectionBackground` }}>
        <div sx={{ maxWidth: 800, mx: `auto` }}>
          <h1
            sx={{
              my: 0,
              fontWeight: `600`,
              fontSize: [`2rem`, `2.5rem`, `3rem`],
              letterSpacing: `-0.02em`,
            }}
          >
            The
            {` `}
            <mark
              sx={{
                px: 1,
                mx: -1,
                bg: `transparent`,
                backgroundColor: `characterPrimaryBackground`,
              }}
            >
              {attribute}
            </mark>
            {` `}
            collection
          </h1>

          <p sx={{ mt: 2, mb: 4, fontSize: [`1rem`, `1.25rem`, `1.5rem`] }}>
            All characters that share the "{attribute}" attribute
          </p>

          <div
            sx={{
              my: 3,
              display: `grid`,
              gridTemplateColumns: `repeat(auto-fit, minmax(264px, 1fr))`,
              gap: `0.25rem`,
            }}
          >
            <div
              sx={{
                p: 2,
                display: `grid`,
                alignItems: `end`,
                color: `characterPrimaryText`,
                bg: `characterPrimaryBackground`,
              }}
            >
              Claimed
            </div>

            <div sx={{ p: 2, display: `grid`, alignItems: `end`, color: `white`, bg: `royalblue` }}>Unclaimed</div>
            {/* <div sx={{ p: 2, display: `grid`, alignItems: `end`, color: `white`, bg: `red` }}>Dropping soon</div> */}
            <div sx={{ p: 2, display: `grid`, alignItems: `end`, color: `black`, bg: `silver` }}>Not yet available</div>
          </div>
        </div>
      </section>

      <main sx={{ py: 4, px: [`0.5rem`, `0.75rem`], bg: `sectionBackground` }}>
        <div sx={{ maxWidth: 800, mx: `auto` }}>
          <ul
            sx={{
              my: 0,
              pl: 0,
              listStyleType: `none`,
              fontFamily: `monospace`,
              justifyContent: `space-between`,
              gap: `0.25rem`,
              display: `grid`,
              gridTemplateColumns: `repeat(auto-fill, minmax(130px, 1fr))`,
            }}
          >
            {characters.map((character) => (
              <Chip key={character.number} {...character} />
            ))}
          </ul>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default Collection
